import { ComponentType, ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import {
  BodyText,
  CoinsIcon,
  IconProps,
  LinearGradient,
  MapIcon,
  PieGraphSliceIcon,
  Stack,
  StackProps,
  TitleText,
  setColorAlpha,
  tokens,
} from '@arrived/bricks';

const highlights: Array<{ Icon: ComponentType<IconProps>; Title: ReactNode; Content: ReactNode }> = [
  {
    Icon: MapIcon,
    Title: <FormattedMessage id="landing.nationwide-highlight.title" />,
    Content: <FormattedMessage id="landing.nationwide-highlight.content" />,
  },
  {
    Icon: PieGraphSliceIcon,
    Title: <FormattedMessage id="landing.low-minimums-highlight.title" />,
    Content: <FormattedMessage id="landing.low-minimums-highlight.content" />,
  },
  {
    Icon: CoinsIcon,
    Title: <FormattedMessage id="landing.passive-income-highlight.title" />,
    Content: <FormattedMessage id="landing.passive-income-highlight.content" />,
  },
];

export const Highlights = (props: Omit<StackProps, 'children'>) => (
  <Stack {...props}>
    {highlights.map(({ Icon, Title, Content }, idx) => (
      <Stack
        p="$4"
        $gtXs={{ p: '$5', flexBasis: 0, flexGrow: 1, flexShrink: 1 }}
        key={idx}
        overflow="hidden"
        gap="$3"
        br="$2"
      >
        <LinearGradient
          fullscreen
          start={[0, 0.5]}
          end={[1, 0.5]}
          colors={[
            setColorAlpha(tokens.color['primary.light.0'], 0.5),
            setColorAlpha(tokens.color['primary.light.100'], 1.0),
          ]}
        />
        <Stack br="$full" p="$2" alignSelf="flex-start" bg="$interactive.neutral.restedInverted">
          <Icon size="lg" />
        </Stack>
        <Stack gap="$3">
          <TitleText token="title.heading.medium">{Title}</TitleText>
          <BodyText token="body.default.medium">{Content}</BodyText>
        </Stack>
      </Stack>
    ))}
  </Stack>
);
