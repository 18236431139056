import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function PieGraphSliceIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M18.3333 8.74984C18.3333 4.83782 15.162 1.6665 11.25 1.6665V8.74984H18.3333Z"
        stroke={color}
        strokeWidth={1.25}
      />
      <Path
        d="M8.75033 18.3332C12.6623 18.3332 15.8337 15.1618 15.8337 11.2498H8.75033V4.1665C4.83831 4.1665 1.66699 7.33782 1.66699 11.2498C1.66699 15.1618 4.83831 18.3332 8.75033 18.3332Z"
        stroke={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const PieGraphSliceIcon = memo<IconProps>(withIconTheme(PieGraphSliceIconInternal));
