import { useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle, PixelRatio } from 'react-native';

import dynamic from 'next/dynamic';
import Link from 'next/link';

import { FormattedMessage } from 'react-intl';
import { interpolate } from 'react-native-reanimated';

import { CarouselRenderItemInfo } from '@mikehuebner/react-native-reanimated-carousel/lib/typescript/types';

import { OpenSearchOfferingDocument } from '@arrived/api_v2';
import {
  ArrowRightIcon,
  Background,
  BodyText,
  Button,
  Image,
  Stack,
  StackProps,
  TitleText,
  tokens,
  useMedia,
} from '@arrived/bricks';
import { ROUTES } from '@arrived/common';
import { Invest } from '@arrived/features-invest';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';

// The carousel cannot be server-side rendered so we use a dynamic import here to delay loading
// until the initial page has loaded on the client side!
const Carousel = dynamic(() => import('@mikehuebner/react-native-reanimated-carousel'), {
  ssr: false,
});

const images = [
  'https://cdn.arrivedhomes.com/features/landing-pages/press/yahoo.png',
  'https://cdn.arrivedhomes.com/features/landing-pages/press/bloomberg.png',
  'https://cdn.arrivedhomes.com/features/landing-pages/press/businessinsider.png',
  'https://cdn.arrivedhomes.com/features/landing-pages/press/techcrunch.png',
  'https://cdn.arrivedhomes.com/features/landing-pages/press/wsj.png',
  'https://cdn.arrivedhomes.com/features/landing-pages/press/geekwire.png',
];

// We need to double up the # of carousel images to make it appear that the carousel is infinite
const carouselImages = [...images, ...images];

export const Header = ({
  offerings,
  ...rest
}: Omit<StackProps, 'children'> & { offerings: OpenSearchOfferingDocument[] }) => {
  const media = useMedia();

  const [frameLayout, setFrameLayout] = useState<LayoutRectangle>();

  const { carouselWidth, translateX } = useMemo(() => {
    if (!frameLayout?.width) {
      return { carouselWidth: 200, translateX: false };
    }

    let numImages = 2;

    if (media.gtSm) {
      numImages = 6;
    } else if (media.gtXs) {
      numImages = 4;
    } else if (media.gtXxs) {
      numImages = 3;
    } else {
      numImages = 2;
    }

    return { carouselWidth: (frameLayout.width - 24) / numImages, translateX: numImages % 2 === 0 };
  }, [frameLayout?.width, media.gtSm, media.gtXs, media.gtXxs]);

  const offeringsCarouselSize = useMemo(() => {
    if (media.gtXs) {
      return 350;
    } else {
      return 275;
    }
  }, [media.gtXs]);

  const handleOnLayout = useCallback((event: LayoutChangeEvent) => setFrameLayout(event.nativeEvent.layout), []);

  const animationStyle = useCallback((value: number) => {
    const zIndex = interpolate(value, [-1, 0, 1], [0, 1_000, 0]);
    const opacity = interpolate(value, [-1, 0, 1], [0.3, 1, 0.3]);
    const translateX = interpolate(value, [-1, 0, 1], [-200, 0, 200]);
    const scale = interpolate(value, [-1, 0, 1], [0.85, 1, 0.85]);
    const blur = interpolate(value, [-1, 0, 1], [4, 0, 4]);

    return {
      opacity,
      zIndex: Math.round(zIndex),
      filter: `blur(${blur}px)`,
      transform: [{ translateX }, { scale }],
    };
  }, []);

  return (
    <Stack {...rest}>
      <Background.Noise fullscreen zIndex={0} />
      <Stack
        flexDirection="column-reverse"
        gap="$10"
        px="$6"
        $gtXs={{ px: '$10', flexDirection: 'row-reverse', alignItems: 'center' }}
      >
        <Stack $gtXs={{ flexBasis: 0, flexGrow: 1, flexShrink: 1 }}>
          <Carousel
            key={`${media.gtXs}`}
            data={offerings}
            autoPlay
            autoPlayInterval={1_500}
            scrollAnimationDuration={1_000}
            height={offeringsCarouselSize}
            width={offeringsCarouselSize}
            enabled={false}
            customAnimation={animationStyle}
            style={{ alignSelf: 'center', overflow: 'visible' }}
            renderItem={({ item }: CarouselRenderItemInfo<OpenSearchOfferingDocument>) => (
              <Invest.Card height={offeringsCarouselSize} key={item.cid} offering={item} />
            )}
          />
        </Stack>
        <Stack $gtXs={{ flexBasis: 0, flexGrow: 1, flexShrink: 1, gap: '$10' }} alignItems="flex-start" gap="$4">
          <Stack gap="$3" $gtXs={{ gap: '$4', maxWidth: 400 }}>
            <Stack>
              <TitleText
                color="$onSurface.neutral.defaultInverted"
                token="title.display.small"
                $gtXs={{ token: 'title.display.medium' }}
              >
                <FormattedMessage
                  id="landing.header.main-title"
                  values={{
                    gradient: (parts) => (
                      <TitleText
                        token="title.display.small"
                        $gtXs={{ token: 'title.display.medium' }}
                        style={{
                          backgroundImage: `linear-gradient(90deg, ${tokens.color['primary.light.500'].val}, ${tokens.color['positive.light.400'].val})`,
                          backgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        {parts}
                      </TitleText>
                    ),
                  }}
                />
              </TitleText>
            </Stack>
            <BodyText color="$onSurface.neutral.defaultInverted" token="body.default.large">
              <FormattedMessage id="landing.header.content" />
            </BodyText>
          </Stack>
          <Link legacyBehavior href={ROUTES.register}>
            <Button variant="emphasized" Icon={ArrowRightIcon} alignSelf="stretch" $gtXs={{ alignSelf: 'flex-start' }}>
              <FormattedMessage id="landing.header.cta" />
            </Button>
          </Link>
        </Stack>
      </Stack>
      <Stack gap="$4" onLayout={handleOnLayout}>
        <Carousel
          key={`${media.gtXs}`}
          enabled={!media.gtXs}
          loop
          snapEnabled={false}
          width={carouselWidth}
          height={36}
          style={{
            alignSelf: 'center',
            overflow: 'visible',
            transform: `translateX(${translateX ? -carouselWidth / 2 : 0}px)`,
          }}
          data={carouselImages}
          renderItem={({ item, index }: CarouselRenderItemInfo<string>) => (
            <Image
              key={index}
              contentFit="contain"
              // @ts-ignore filter is a valid property, not sure why it's not showing up here
              // all of these images are black, so I used a codepen to generate the filter to apply
              // to get to the target color of #4C5967 (neutral.light.600)
              style={{
                filter: 'invert(33%) sepia(20%) saturate(425%) hue-rotate(170deg) brightness(95%) contrast(91%)',
              }}
              // style={{ filter: 'invert(40%)' }}
              source={ikClient.url({
                path: cdnToImageUrl(item),
                transformation: [{ height: PixelRatio.getPixelSizeForLayoutSize(36).toString() }],
              })}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};
