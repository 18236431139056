import { ReactNode, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';

import { FormattedMessage } from 'react-intl';

import {
  BodyText,
  Gallery,
  GalleryCarouselProps,
  GalleryProps,
  Image,
  RenderItemArgs,
  Stack,
  StackProps,
  TitleText,
  UtilityText,
  withStaticProperties,
} from '@arrived/bricks';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';

interface StepsGalleryItem {
  image: string;
  Title: ReactNode;
  Content: ReactNode;
}

const steps: StepsGalleryItem[] = [
  {
    image: 'https://cdn.arrivedhomes.com/images/landing/step_1.png',
    Title: <FormattedMessage id="landing.steps.step-1.title" />,
    Content: <FormattedMessage id="landing.steps.step-1.content" />,
  },
  {
    image: 'https://cdn.arrivedhomes.com/images/landing/step_2.png',
    Title: <FormattedMessage id="landing.steps.step-2.title" />,
    Content: <FormattedMessage id="landing.steps.step-2.content" />,
  },
  {
    image: 'https://cdn.arrivedhomes.com/images/landing/step_3.png',
    Title: <FormattedMessage id="landing.steps.step-3.title" />,
    Content: <FormattedMessage id="landing.steps.step-3.content" />,
  },
];

const StepsGalleryItem = ({ index, item: { image, Title, Content } }: RenderItemArgs<StepsGalleryItem>) => {
  const [layoutRectangle, setLayoutRectangle] = useState<LayoutRectangle>();

  const onLayout = useCallback((event: LayoutChangeEvent) => setLayoutRectangle(event.nativeEvent.layout), []);

  const imageSize = useMemo(() => {
    if (layoutRectangle) {
      return Math.min(layoutRectangle?.height, layoutRectangle.width);
    } else {
      return 250;
    }
  }, [layoutRectangle]);

  return (
    <Stack flex={1} key={index} gap="$4" $gtXs={{ gap: '$6' }}>
      <Stack flex={1} onLayout={onLayout} centered>
        <Image
          width={imageSize}
          height={imageSize}
          flex={0}
          source={ikClient.url({ path: cdnToImageUrl(image), transformation: [{ width: '600' }] })}
          br="$2"
        />
      </Stack>
      <Stack gap="$3">
        <UtilityText color="$onSurface.neutral.muted" token="utility.label.xsmall" textTransform="uppercase">
          <FormattedMessage id="landing.steps.step-number" values={{ stepNumber: index + 1 }} />
        </UtilityText>
        <TitleText token="title.heading.medium">{Title}</TitleText>
        <BodyText token="body.default.large">{Content}</BodyText>
      </Stack>
    </Stack>
  );
};

const StepsGallery = ({
  children,
  ...rest
}: Omit<GalleryProps<StepsGalleryItem>, 'children' | 'items'> &
  Pick<GalleryCarouselProps<StepsGalleryItem>, 'children'>) => {
  return (
    <Gallery {...rest} items={steps}>
      <Gallery.Carousel overflow="visible" renderItem={StepsGalleryItem}>
        {children}
      </Gallery.Carousel>
    </Gallery>
  );
};

const StepsRow = (props: Omit<StackProps, 'children'>) => (
  <Stack {...props}>
    {steps.map(({ image, Title, Content }, idx) => (
      <Stack flexBasis={0} flexGrow={1} flexShrink={1} key={idx} gap="$4" $gtXs={{ gap: '$10' }}>
        <Image
          width="100%"
          aspectRatio={1}
          source={ikClient.url({ path: cdnToImageUrl(image), transformation: [{ width: '600' }] })}
          br="$2"
        />
        <Stack gap="$3">
          <UtilityText color="$onSurface.neutral.muted" token="utility.label.xsmall" textTransform="uppercase">
            <FormattedMessage id="landing.steps.step-number" values={{ stepNumber: idx + 1 }} />
          </UtilityText>
          <TitleText token="title.heading.medium">{Title}</TitleText>
          <BodyText token="body.default.large">{Content}</BodyText>
        </Stack>
      </Stack>
    ))}
  </Stack>
);

export const StepsToInvest = withStaticProperties(
  ({ children, ...rest }: StackProps) => (
    <Stack {...rest}>
      <TitleText token="title.heading.xlarge">
        <FormattedMessage id="landing.steps.title" />
      </TitleText>
      {children}
    </Stack>
  ),
  {
    Gallery: StepsGallery,
    Row: StepsRow,
  },
);
