import { PixelRatio } from 'react-native';

import { FormattedMessage, useIntl } from 'react-intl';

import { BodyText, Image, Stack, StackProps, TitleText, UtilityText } from '@arrived/bricks';
import { Markdown } from '@arrived/bricks-common';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';

export const ArrivedComparedToOtherAssets = (props: StackProps) => {
  const intl = useIntl();

  return (
    <Stack {...props}>
      <Image
        source={ikClient.url({
          path: cdnToImageUrl('https://cdn.arrivedhomes.com/images/landing/arrived_vs.png'),
          transformation: [{ height: PixelRatio.getPixelSizeForLayoutSize(580).toString() }],
        })}
        contentFit="contain"
        aspectRatio={1}
        maxWidth={450}
        width="100%"
        alignSelf="center"
        $gtXs={{ flexBasis: 0, flexGrow: 1, flexShrink: 1, maxWidth: 580 }}
        borderRadius="$2"
      />
      <Stack gap="$4" $gtXs={{ gap: '$10', flexBasis: 0, flexGrow: 1, flexShrink: 1 }}>
        <Stack gap="$2" $gtXs={{ gap: '$6' }}>
          <TitleText token="title.heading.xlarge">
            <FormattedMessage id="landing.arrived-vs.header" />
          </TitleText>
          <BodyText token="body.default.large">
            <FormattedMessage id="landing.arrived-vs.content" />
          </BodyText>
        </Stack>
        <Markdown
          Text={UtilityText}
          TextProps={{ color: '$onSurface.neutral.muted', token: 'utility.legal' }}
          LinkPropsOverrides={{ color: '$onSurface.neutral.muted' }}
        >
          {intl.formatMessage({ id: 'landing.arrived-vs.disclaimer' })}
        </Markdown>
      </Stack>
    </Stack>
  );
};
