import { useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { BodyText, LinearGradient, Stack, StackProps, TitleText, setColorAlpha, styled, tokens } from '@arrived/bricks';
import { AccordionList, Markdown } from '@arrived/bricks-common';

const QuestionText = styled(TitleText, {
  token: 'title.heading.medium',

  // @ts-expect-error we are intentionally not adding `CommonQuestion` to the type override for
  // groups to avoid cross pollination of packages.
  '$group-CommonQuestion-hover': {
    color: '$interactive.primary.hovered',
  },
});
const AnswerText = styled(BodyText, { token: 'body.default.medium' });

// Pulled this out and put it in a variable to make sure that the `px` and `mx` attributes that use
// it stay equal as that matters how this visually appears on the client.
const horizontalWrapperPadding = tokens.space['4'].val

const Wrapper = styled(Stack, {
  animation: 'quick',
  row: true,
  button: true,
  cursor: 'pointer',
  px: horizontalWrapperPadding,
  py: '$3',
  gap: '$2',
  $xxs: {
    mx: -horizontalWrapperPadding,
  },

  // @ts-expect-error we are intentionally not adding `CommonQuestion` to the type override for
  // groups to avoid cross pollination of packages.
  group: 'CommonQuestion',
});

export const CommonQuestions = (props: StackProps) => {
  const intl = useIntl();

  // These are a `useMemo` const inside of the component so that in the future we can easily
  // extract these id strings. The `answer` attribute has to use `intl.formatMessage` because
  // it needs to be rendered in the `Markdown` component which needs a `string` not a `ReactNode`.
  const faqs = useMemo(
    () => [
      {
        Question: <FormattedMessage id="landing.faqs.how-does-arrived-make-money.question" />,
        answer: intl.formatMessage({ id: 'landing.faqs.how-does-arrived-make-money.answer' }),
      },
      {
        Question: <FormattedMessage id="landing.faqs.how-is-my-investment-protected.question" />,
        answer: intl.formatMessage({ id: 'landing.faqs.how-is-my-investment-protected.answer' }),
      },
      {
        Question: <FormattedMessage id="landing.faqs.what-are-the-risks.question" />,
        answer: intl.formatMessage({ id: 'landing.faqs.what-are-the-risks.answer' }),
      },
      {
        Question: <FormattedMessage id="landing.faqs.choose-properties.question" />,
        answer: intl.formatMessage({ id: 'landing.faqs.choose-properties.answer' }),
      },
      {
        Question: <FormattedMessage id="landing.faqs.differ-from-traditional-investing.question" />,
        answer: intl.formatMessage({ id: 'landing.faqs.differ-from-traditional-investing.answer' }),
      },
      {
        Question: <FormattedMessage id="landing.faqs.how-experienced-is-the-team.question" />,
        answer: intl.formatMessage({ id: 'landing.faqs.how-experienced-is-the-team.answer' }),
      },
      {
        Question: <FormattedMessage id="landing.faqs.jeff-bezos.question" />,
        answer: intl.formatMessage({ id: 'landing.faqs.jeff-bezos.answer' }),
      },
    ],
    [intl],
  );

  return (
    <Stack {...props}>
      <LinearGradient
        fullscreen
        start={[0, 0.5]}
        end={[1, 0.5]}
        colors={[
          setColorAlpha(tokens.color['primary.light.0'], 0.5),
          setColorAlpha(tokens.color['primary.light.100'], 1.0),
        ]}
      />
      <AccordionList>
        {faqs.map(({ answer, Question }, idx) => (
          <AccordionList.Item Wrapper={Wrapper} Content={() => <QuestionText>{Question}</QuestionText>} key={idx}>
            <AccordionList.Item.Children>
              <Markdown Text={AnswerText}>{answer}</Markdown>
            </AccordionList.Item.Children>
          </AccordionList.Item>
        ))}
      </AccordionList>
    </Stack>
  );
};
