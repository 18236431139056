import { useRouter } from 'next/router';

import { getCompanyKpis, getCustomerCount, getOfferingsSearch } from '@arrived/api_v2';
import { Gallery, Stack, tokens } from '@arrived/bricks';
import { DisclaimerAccordion } from '@arrived/components';
import { useFeature } from '@arrived/feature-flags';
import { OfferingStatus, OfferingType } from '@arrived/models';

import { ArrivedComparedToOtherAssets } from './ArrivedComparedToOtherAssets';
import { CommonQuestions } from './CommonQuestions';
import { Header } from './Header';
import { Highlights } from './Highlights';
import { KPIs } from './KPIs';
import { StepsToInvest } from './StepsToInvest';

export const getStaticProps = async () => {
  const [offerings, kpis, customerCount] = await Promise.all([
    getOfferingsSearch({
      type: { eq: [OfferingType.EQUITY] },
      status: { eq: [OfferingStatus.TRANSACT_ALL, OfferingStatus.FUNDED] },
      sort: 'propertyRank:desc,id:desc',
      size: 3,
    }).then(({ data }) => data),
    getCompanyKpis(),
    getCustomerCount().then(({ count }) => count),
  ]);

  return {
    props: {
      customerCount,
      kpis,
      offerings,
    },
    revalidate: 600,
  };
};

// This constant is used to add extra padding to the "Steps to Invest" carousel and also
// used to absolutely position the pagination component below the carousel. The constant, 14, is
// exactly the height of the pagination component, because we want there to visually be $6 padding
// between the carousel and the pagination component, we add the value for space['6'].
const paginationSpacing = tokens.space['6'].val + 14;

export const ConversionPage = ({
  customerCount,
  kpis,
  offerings,
}: Awaited<ReturnType<typeof getStaticProps>>['props']) => {
  const router = useRouter();

  const abTestSearchParam = router.query?.['abptff'] as string;
  useFeature(abTestSearchParam);

  return (
    <>
      <Stack $gtXs={{ px: '$4', py: '$4' }}>
        <Stack width="100%" mx="auto" maxWidth={1440} gap="$4">
          <Header
            bg="$interactive.neutral.rested"
            bblr="$2"
            bbrr="$2"
            pt="$6"
            pb="$6"
            $gtXs={{ pt: '$30', pb: '$10', br: '$2', gap: '$30' }}
            overflow="hidden"
            gap="$10"
            offerings={offerings}
          />
          <Highlights px="$4" gap="$4" $gtXs={{ row: true, px: 0 }} />
        </Stack>
        <Stack
          width="100%"
          mx="auto"
          maxWidth={1440}
          px="$4"
          py="$10"
          gap="$10"
          $gtXs={{ px: '$12', py: '$20', gap: '$20' }}
          $gtMd={{ py: '$30', gap: '$30' }}
        >
          <StepsToInvest pb={paginationSpacing} $gtXs={{ pb: 0 }} gap="$4" $gtMd={{ gap: '$10' }}>
            <StepsToInvest.Gallery $gtXs={{ display: 'none' }} height={500} overflow="visible">
              <Gallery.Carousel.Pagination colors="dark" bottom={-paginationSpacing} />
            </StepsToInvest.Gallery>
            <StepsToInvest.Row
              alignItems="flex-start"
              row
              gap="$4"
              $gtXs={{ display: 'flex' }}
              $gtMd={{ gap: '$10' }}
              display="none"
            />
          </StepsToInvest>
          <KPIs
            bw="$0.25"
            boc="$onSurface.neutral.outlineAlt"
            px="$10"
            py="$6"
            gap="$6"
            $gtXs={{ gap: 0, row: true, py: '$12', justifyContent: 'space-around' }}
            br="$2"
            customerCount={customerCount}
            dividendsPaid={kpis.totalDividendsPaid}
            occupancyRate={kpis.occupancy.ltrOccupancyRate}
            totalAum={kpis.totalAum}
          />
          <ArrivedComparedToOtherAssets
            gap="$4"
            $gtXs={{ gap: '$6', row: true, alignItems: 'center' }}
            $gtMd={{ gap: '$10' }}
          />
        </Stack>
        <CommonQuestions
          width="100%"
          mx="auto"
          maxWidth={1440}
          px="$4"
          py="$6"
          $gtXs={{ px: '$20', py: '$20', br: '$4' }}
          overflow="hidden"
        />
      </Stack>
      <DisclaimerAccordion />
    </>
  );
};
