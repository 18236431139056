import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';
import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

export const MapIconInternal = ({ size, color, ...rest }: IconProps) => (
  <Svg height={size} width={size} fill="none" viewBox="0 0 20 20" {...rest}>
    <Path
      d="M7.14469 17.2368L1.44735 18.5527V2.76323L7.14469 1.44745L12.8552 2.76323L18.5526 1.44745V17.2368L12.8552 18.5527L7.14469 17.2368Z"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.25"
    />
    <Path d="M7.15454 1.44968V17.2406" stroke={color} strokeWidth="1.25" />
    <Path d="M12.8455 2.76398V18.5482" stroke={color} strokeWidth="1.25" />
  </Svg>
);

export const MapIcon = memo<IconProps>(withIconTheme(MapIconInternal));
