import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DataPoint, Stack, StackProps, styled } from '@arrived/bricks';

export type KPIsProps = StackProps & {
  customerCount: number;
  dividendsPaid: number;
  occupancyRate: number;
  totalAum: number;
};

const StyledDataPoint = styled(DataPoint, {
  alignment: 'center',
  variant: 'elevateAlt',
  colors: 'dark',
});

export const KPIs = ({ customerCount, dividendsPaid, occupancyRate, totalAum, ...rest }: KPIsProps) => (
  <Stack {...rest}>
    <StyledDataPoint>
      <DataPoint.Value>
        <FormattedNumber
          value={dividendsPaid}
          style="currency"
          currency="USD"
          maximumFractionDigits={0}
          notation="compact"
          minimumSignificantDigits={2}
          maximumSignificantDigits={2}
        />
      </DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id="landing.kpis.dividends-paid" />
      </DataPoint.Label>
    </StyledDataPoint>
    <StyledDataPoint>
      <DataPoint.Value>
        <FormattedNumber
          value={customerCount}
          maximumFractionDigits={0}
          notation="compact"
          minimumSignificantDigits={3}
          maximumSignificantDigits={3}
        />
      </DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id="landing.kpis.registered-investors" />
      </DataPoint.Label>
    </StyledDataPoint>
    <StyledDataPoint>
      <DataPoint.Value>
        <FormattedNumber
          value={totalAum}
          style="currency"
          currency="USD"
          maximumFractionDigits={0}
          notation="compact"
          minimumSignificantDigits={3}
          maximumSignificantDigits={3}
        />
      </DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id="landing.kpis.assets-under-management" />
      </DataPoint.Label>
    </StyledDataPoint>
    <StyledDataPoint
      tooltip={{
        content: <FormattedMessage id="landing.kpis.occupancy-rate.content" />,
        title: <FormattedMessage id="landing.kpis.occupancy-rate" />,
      }}
    >
      <DataPoint.Value>
        <FormattedNumber
          value={occupancyRate}
          style="percent"
          maximumFractionDigits={0}
          minimumSignificantDigits={2}
          maximumSignificantDigits={2}
        />
      </DataPoint.Value>
      <DataPoint.Label>
        <FormattedMessage id="landing.kpis.occupancy-rate" />
      </DataPoint.Label>
    </StyledDataPoint>
  </Stack>
);
